import {
  takeLatest,
  takeEvery,
  take,
  call,
  put,
  select,
} from 'redux-saga/effects';
import { EventEmitter } from 'events';
import sortBy from 'lodash.sortby';
import unionWith from 'lodash.unionwith';
import { initListener, initWebrtcListener } from './nethesisSagas';
import { fetchMePhoneRules } from '../phoneRules/sagas';
import {
  LOGIN_PHONE_SUCCESS,
  LOGIN_PHONE_FAILURE,
  RELOGIN_PHONE_REQUEST,
  INITIAL_ABILIS_PHONE_STATUS_SUCCESS,
  INITIAL_NETHESIS_PHONE_STATUS_SUCCESS,
  ABILIS_PHONE_STATUS_CHANGES_SUCCESS,
  EXEC_CALL_REQUEST,
  HANGUP_CALL_REQUEST,
  HANGUP_HOLD_CALL_REQUEST,
  TOGGLE_PHONE_DND_REQUEST,
  FETCH_INTERLOCUTOR_DATA_REQUEST,
  BLIND_TRANSFER_REQUEST,
  FETCH_SEARCHED_CONTACTS_REQUEST,
  HOLD_CALL_REQUEST,
  AID_TRANSFER_REQUEST,
  UNHOLD_CALL_REQUEST,
  LINK_CALLS_REQUEST,
  PARK_CALL_REQUEST,
  SWITCH_CALLS_REQUEST,
  PICKUP_CALL_REQUEST,
  PICKUP_PARK_REQUEST,
  PICKUP_QUEUE_REQUEST,
  TOGGLE_MUTE_REQUEST,
  ACTIVATE_WEBRTC_REQUEST,
  DEACTIVATE_WEBRTC_REQUEST,
  ANSWER_CALL_REQUEST,
  DECLINE_CALL_REQUEST,
  RECORD_CALL_REQUEST,
  SEND_DTMF_REQUEST,
  START_CONFERENCE_REQUEST,
  START_CONFERENCE_SUCCESS,
  END_CONFERENCE_REQUEST,
  END_CONFERENCE_SUCCESS,
  FETCH_MEMBER_DATA_REQUEST,
  FETCH_CONFERENCE_REQUEST,
  JOIN_CONFERENCE_REQUEST,
  JOIN_CONFERENCE_SUCCESS,
  HANGUP_USER_CONFERENCE_REQUEST,
  HANGUP_USER_CONFERENCE_SUCCESS,
  SPY_CALL_REQUEST,
  INTRUDE_CALL_REQUEST,
  PHONE_STATUS_CHANGES_FAILURE,
  ENABLE_PHONE_DND,
  DISABLE_PHONE_DND,
  ACTIVATE_WEBRTC_FAILURE,
  QUEUE_TRANSFER_REQUEST,
  HANGUP_CHANNEL_REQUEST,
  SEND_STOP_USE_WEBPHONE_REQUEST,
  STOP_USE_WEBPHONE,
  BUSY_WEBRTC_CALL,
  WEBPHONE_DISCONNECTED,
  REACTIVATE_WEBRTC_PHONE,
  FETCH_PBX_ROUTES_REQUEST,
  SAVE_PBX_ROUTE_REQUEST,
  FETCH_PHONE_SETTINGS_REQUEST,
  LOGIN_PHONE_REQUEST,
  STOP_RECORD_CALL_REQUEST,
  RECEIVED_TRUNK_UPDATE,
  ADD_CONFERENCE_MEMBER_WEBRTC_REQUEST,
  ADD_CONFERENCE_MEMBER_WEBRTC_SUCCESS,
  FETCH_PBX_QUEUES_REQUEST,
  SAVE_PBX_QUEUE_REQUEST,
  TRACE_WEBRTC_TROUBLE,
} from './types';
import {
  loginPhoneSuccess,
  loginPhoneFailure,
  initialAbilisPhonesStatusSuccess,
  initialNethesisPhonesStatusSuccess,
  initialPhonesStatusFailure,
  getAbilisPhonesStatusChangesSuccess,
  getPhonesStatusChangesFailure,
  execCallSuccess,
  execCallFailure,
  hangupCallSuccess,
  hangupCallFailure,
  hangupHoldCallSuccess,
  hangupHoldCallFailure,
  toggleDNDSuccess,
  toggleDNDFailure,
  fetchInterlocutorDataSuccess,
  fetchInterlocutorDataFailure,
  blindTransferSuccess,
  blindTransferFailure,
  fetchSearchedContactsSuccess,
  fetchSearchedContactsFailure,
  holdCallSuccess,
  holdCallFailure,
  unholdCallSuccess,
  unholdCallFailure,
  aidTransferSuccess,
  aidTransferFailure,
  linkCallsSuccess,
  linkCallsFailure,
  parkCallSuccess,
  parkCallFailure,
  switchCallsSuccess,
  switchCallsFailure,
  pickupCallSuccess,
  pickupCallFailure,
  pickupParkSuccess,
  pickupParkFailure,
  pickupQueueSuccess,
  pickupQueueFailure,
  toggleMuteSuccess,
  toggleMuteFailure,
  activateWebrtcPhoneFailure,
  deactivateWebrtcPhoneSuccess,
  deactivateWebrtcPhoneFailure,
  answerCallSuccess,
  answerCallFailure,
  declineCallSuccess,
  declineCallFailure,
  recordCallSuccess,
  recordCallFailure,
  sendDtmfSuccess,
  sendDtmfFailure,
  startConferenceSuccess,
  startConferenceFailure,
  endConferenceSuccess,
  endConferenceFailure,
  fetchConferenceSuccess,
  fetchConferenceFailure,
  fetchMemberDataSuccess,
  fetchMemberDataFailure,
  fetchConferenceRequest,
  joinConferenceSuccess,
  joinConferenceFailure,
  hangupUserConferenceSuccess,
  hangupUserConferenceFailure,
  spyCallSuccess,
  spyCallFailure,
  intrudeCallSuccess,
  intrudeCallFailure,
  activateWebrtcPhoneRequest,
  deactivateWebrtcPhoneRequest,
  logoutPhoneSuccess,
  logoutPhoneFailure,
  fetchPhoneSettingsSuccess,
  fetchPhoneSettingsFailure,
  nethesisWebsocketDisconnected,
  queueTransferSuccess,
  queueTransferFailure,
  hangupChannelSuccess,
  hangupChannelFailure,
  unsetCalledBusy,
  resetWebrtcSpy,
  fetchDefaultDeviceSuccess,
  webphoneDisconnected,
  setNoValidJsepCall,
  fetchPbxRoutesSuccess,
  fetchPbxRoutesFailure,
  savePbxRouteFailure,
  savePbxRouteSuccess,
  fetchPbxRoutesRequest,
  fetchPhoneSettingsRequest,
  loginPhoneRequest,
  stopRecordCallSuccess,
  addConferenceMemberWebrtcSuccess,
  addConferenceMemberWebrtcFailure,
  fetchPbxQueuesSuccess,
  fetchPbxQueuesFailure,
  savePbxQueueSuccess,
  fetchPbxQueuesRequest,
  savePbxQueueFailure,
} from './actions';
import { fetchCachedContactSuccess } from '../contacts/actions';
import {
  getAbilisMePhonesStatusChangesSuccess,
  getNethesisMePhonesStatusChangesSuccess,
} from '../me/actions';
import {
  getSupplier,
  getMainPhoneData,
  getOthersPhoneData,
  getPbxData,
  getExtensionsUsernameByNumbers,
  isWebrtcActive,
  getMeMainExtensionUsername,
  getMeMainExtensionNumber,
  getIncomingCallJsep,
  getPhoneError,
  getMeWebrtcExtensionNumber,
  sentStopUseWebphoneError,
  isWebrtcInUse,
  isPhoneLogged,
} from './selectors';
import { getUsersWithPhone } from '../users/selectors';
import { getCachedContacts } from '../contacts/selectors';
import {
  getMeId,
  getMeConferenceCallId,
  getMeIncomingCalls,
  otherSessions,
  getMeCallsAndConferences,
  getMeConferenceId,
  addressbookGrant,
  getMeAlias,
} from '../me/selectors';
import { PbxManager } from './PbxManager';
import { PhoneEnums, SIP_DEVICE_NAME } from './PhoneUtils';
import { PbxSettings, PHONE_RECONNECT_TIMEOUT } from './PbxSettingsUtils';
import api from '../api';
import { LOGOUT_SUCCESS } from '../auth/types';
import { INIT_MAIN_SUCCESS } from '../me/types';
import { checkApiResponse, checkApiError } from '../rootSaga';
import {
  calculateReconnectWaiting,
  generateAckUid,
  WsEnums,
} from '../websocket/WsUtils';
import Utils from '../lib/utils';
import { isAuthenticated } from '../auth/selectors';
import AckHandler from '../websocket/AckHandler';
import WsManager from '../websocket/WsManager';
import { PresenceEnums } from '../users/PresenceUtils';
import { Janus } from './janus';

let nethesisWsattempt = 0;
let nethesisJanusAttempt = 0;
let socket = null;

export function* fetchPhoneSettings() {
  const meMainUsername = yield select(getMeMainExtensionUsername);
  if (meMainUsername) {
    try {
      const res = yield call(api.phone.getSettings);
      yield call(checkApiResponse, res);
      yield put(fetchPhoneSettingsSuccess(res.data));
      const phoneLogged = yield select(isPhoneLogged);
      if (!phoneLogged) {
        yield put(loginPhoneRequest());
      }
    } catch (err) {
      const error = yield call(checkApiError, err);
      if (error) yield put(fetchPhoneSettingsFailure(error));
    }
  }
}

export function* loginPhone() {
  const supplier = yield select(getSupplier);
  const pbxData = yield select(getPbxData);
  const mainPhoneData = yield select(getMainPhoneData);
  const othersPhoneData = yield select(getOthersPhoneData);
  const isInError = yield select(getPhoneError);
  if (mainPhoneData.number !== PbxSettings.DISABLED_PHONE_NUMBER) {
    const name = yield select(getMeAlias);
    PbxManager.init(supplier, pbxData, mainPhoneData, othersPhoneData, name);
    try {
      const status = yield call(
        PbxManager.login.bind(
          PbxManager,
          isInError ? PHONE_RECONNECT_TIMEOUT : null
        )
      );
      if (status === PhoneEnums.CallsActionsResults.ko) {
        throw status;
      }
      yield put(loginPhoneSuccess());
    } catch (err) {
      yield put(loginPhoneFailure(err));
    }
  }
}

export function* reloginPhone() {
  yield Utils.delay(5000);
  yield call(loginPhone);
}

export function* logoutPhone() {
  try {
    const response = yield call(PbxManager.logout.bind(PbxManager));
    if (response.status === PhoneEnums.CallsActionsResults.ko) {
      throw response.status;
    }
    yield put(logoutPhoneSuccess());
  } catch (err) {
    yield put(logoutPhoneFailure(err));
  }
}

export function* fetchDefaultDevice() {
  // check for other sessions,
  try {
    const type = yield call(PbxManager.retrieveDefaultDevice.bind(PbxManager));
    yield put(fetchDefaultDeviceSuccess(type));
    if (type && type === 'webrtc') {
      //  wait few seconds for getting
      // my other sessions via ws USER_PRESENCE
      // and activate webrtc
      yield Utils.delay(2000);
      const otherSessionsNumber = yield select(otherSessions);
      const webrtcUsing = yield select(isWebrtcInUse);
      if (otherSessionsNumber === 0 && !webrtcUsing) {
        yield put(activateWebrtcPhoneRequest());
      }
    } else {
      yield put(deactivateWebrtcPhoneRequest());
    }
  } catch (err) {}
}

export function* getInitialPhonesStatus() {
  try {
    const isUserLogged = yield select(isAuthenticated);
    if (!isUserLogged) {
      return;
    }
    const response = yield call(PbxManager.getInitialStatus.bind(PbxManager));
    if (response.status === PhoneEnums.CallsActionsResults.ko) {
      throw response.status;
    }
    const supplier = yield select(getSupplier);
    yield put(
      supplier === PhoneEnums.PbxType.ABILIS
        ? initialAbilisPhonesStatusSuccess(response)
        : initialNethesisPhonesStatusSuccess(response)
    );
    if (supplier === PhoneEnums.PbxType.ABILIS && response.calls.length > 0) {
      const meMainUsername = yield select(getMeMainExtensionUsername);
      const myCalls = response.calls.filter(
        (o) => o.username === meMainUsername
      );
      if (myCalls.length > 0) {
        yield put(getAbilisMePhonesStatusChangesSuccess({ calls: myCalls }));
      }
    }
    if (supplier === PhoneEnums.PbxType.NETHESIS) {
      yield put(fetchPbxQueuesRequest());
    }
    if (nethesisWsattempt) {
      nethesisWsattempt = 0;
    }
  } catch (err) {
    yield put(initialPhonesStatusFailure(err));
  }
}

export function* getAbilisPhonesStatusChanges() {
  const supplier = yield select(getSupplier);
  if (supplier !== PhoneEnums.PbxType.ABILIS) {
    return;
  }
  try {
    const response = yield call(PbxManager.getStatusChanges.bind(PbxManager));
    if (response.status === PhoneEnums.CallsActionsResults.ko) {
      throw response.status;
    }
    yield put(getAbilisPhonesStatusChangesSuccess(response));
    if (response.calls.length > 0) {
      const meMainUsername = yield select(getMeMainExtensionUsername);
      const myCalls = response.calls.filter(
        (o) => o.username === meMainUsername || o.conference
      );
      if (myCalls.length > 0) {
        yield put(getAbilisMePhonesStatusChangesSuccess({ calls: myCalls }));
      }
    }
  } catch (err) {
    yield put(getPhonesStatusChangesFailure(err));
  }
}

export function* getNethesisPhonesStatusChanges() {
  if (socket) {
    return;
  }
  const isUserLogged = yield select(isAuthenticated);
  if (!isUserLogged) {
    return;
  }
  // connect to the server
  console.log('Pbx websocket trying to connect');
  socket = yield call(PbxManager.getStatusChanges.bind(PbxManager));
  if (!socket) {
    nethesisWsattempt += 1;
    yield put(fetchPhoneSettingsRequest());
    yield call(wsReconnect);
    return;
  }
  console.log('Pbx websocket connected');
  if (nethesisWsattempt) {
    yield call(getInitialPhonesStatus);
  }
  // then create a socket channel
  const socketChannel = yield call(initListener, socket);
  // login to websocket server
  yield call(PbxManager.loginWebsocket.bind(PbxManager));

  while (true) {
    const payload = yield take(socketChannel);
    if (payload.disconnect) {
      socket = null;
      break;
    }
    yield put(payload.action(payload.data));
    const meMainUsername = yield select(getMeMainExtensionUsername);
    const meMainNumber = yield select(getMeMainExtensionNumber);
    const webrtcNumber = yield select(getMeWebrtcExtensionNumber);
    const isWebrtc = yield select(isWebrtcActive);
    const conferenceCallId = yield select(getMeConferenceCallId);
    // if webrtc is active and user answers by physical phone -> switch to Physical
    if (
      isWebrtc === 'ON' &&
      payload.data &&
      payload.data.user &&
      payload.data.user.number === meMainNumber &&
      payload.data.user.username === meMainUsername &&
      payload.data.calls.filter(
        (callData) =>
          callData.status === PhoneEnums.CallsStatuses.active &&
          callData.usedDevice !== SIP_DEVICE_NAME
      ).length > 0
    ) {
      yield put(deactivateWebrtcPhoneRequest());
    }
    if (
      payload.data &&
      payload.data.user &&
      payload.data.user.number === webrtcNumber
    ) {
      const data =
        payload.data.user.status === PhoneEnums.UserStatuses.unavailable
          ? 'physical'
          : 'webrtc';

      yield put(fetchDefaultDeviceSuccess(data));
    }
    if (
      (payload.data &&
        payload.data.user &&
        payload.data.user.username === meMainUsername) ||
      (payload.data &&
        payload.data.calls &&
        payload.data.calls.length > 0 &&
        payload.data.calls.filter((callData) => callData.conference).length > 0)
    ) {
      yield put(
        getNethesisMePhonesStatusChangesSuccess({
          ...payload.data,
          webrtc: isWebrtc,
        })
      );

      yield put(resetWebrtcSpy());
    }
    if (
      (payload.data &&
        payload.data.calls &&
        payload.data.calls.length > 0 &&
        payload.data.calls.filter((callData) => callData.conference).length >
          0) ||
      (payload.data &&
        payload.data.user &&
        payload.data.user.status === PhoneEnums.UserStatuses.available &&
        conferenceCallId)
    ) {
      yield put(fetchConferenceRequest());
    }
  }
  // here wew are, there was a disconnect event
  yield put(nethesisWebsocketDisconnected());
  nethesisWsattempt += 1;
  yield call(wsReconnect);
}

function* wsReconnect() {
  const waiting = calculateReconnectWaiting(nethesisWsattempt);
  console.log(
    `Pbx websocket trying to reconnect after ${waiting} milliseconds`
  );
  yield Utils.delay(waiting);
  yield call(getNethesisPhonesStatusChanges);
}

export function* getInterlocutorData(number, parseExitCode) {
  if (!number) {
    return null;
  }
  let interlocutor = {};
  const extensionsNumbers = yield select(getExtensionsUsernameByNumbers);
  if (extensionsNumbers[number]) {
    const users = yield select(getUsersWithPhone);
    interlocutor = users
      .filter(
        (user) => user.mainExtensionUsername === extensionsNumbers[number]
      )
      .map((user) => ({
        id: user.id,
        number: user.mainExtensionNumber,
        fullname: user.fullname,
        departmentFullname: user.departmentFullname,
        avatar: user.avatar,
        isExtension: true,
        interlocutorType: 'U',
        username: user.username,
      }))[0] || {
      number,
      fullname: extensionsNumbers[number],
    };
  } else {
    const exitCode = PbxManager.retrieveExitCode();
    if (parseExitCode && exitCode && number.indexOf(exitCode) === 0) {
      number = number.substr(exitCode.length);
    }
    const cachedContacts = yield select(getCachedContacts);
    if (Object.keys(cachedContacts).indexOf(number) >= 0) {
      return cachedContacts[number];
    }
    const callGrant = yield select(addressbookGrant);
    if (callGrant) {
      const contact = yield call(api.contacts.getContactFromNumber, { number });
      if (contact.status < 200 || contact.status >= 400) {
        throw contact.status;
      } else if (contact.status === 200) {
        interlocutor = {
          id: contact.data.id,
          number,
          fullname: contact.data.fullname,
          avatar: contact.data.avatar,
          interlocutorType: contact.data.contactType,
          numbers: contact.data.numbers,
          clientNumber: contact.data.clientNumber,
        };
      } else if (contact.status === 204) {
        interlocutor = { number };
      }
    } else {
      interlocutor = { number };
    }
    yield put(fetchCachedContactSuccess(interlocutor));
  }
  return interlocutor;
}

export function* fetchInterlocutorData(action) {
  try {
    const { context } = action.payload;
    let callingData;
    switch (context) {
      case 'park':
        callingData = yield* getInterlocutorData(
          action.payload.data.calling,
          false
        );
        yield put(
          fetchInterlocutorDataSuccess({
            data: {
              id: action.payload.data.id,
              callingData,
            },
            context,
          })
        );
        break;
      case 'calls':
      case 'queue':
      default:
        const calledData =
          action.payload.data.called && action.payload.data.called !== ''
            ? yield* getInterlocutorData(action.payload.data.called, true)
            : undefined;
        callingData = yield* getInterlocutorData(
          action.payload.data.calling,
          false
        );

        const callData = {
          ...action.payload.data,
          calledData,
          callingData,
        };
        yield put(
          fetchInterlocutorDataSuccess({
            data: callData,
            id: action.payload.data.id,
            context,
          })
        );
        break;
    }
  } catch (err) {
    yield put(fetchInterlocutorDataFailure(err));
  }
}

export function* fetchMemberData(action) {
  try {
    const data = yield* getInterlocutorData(action.number, true);
    const usernames = yield select(getExtensionsUsernameByNumbers);
    yield put(
      fetchMemberDataSuccess({
        ...data,
        username: usernames[action.number] || action.number,
      })
    );
  } catch (err) {
    yield put(fetchMemberDataFailure(err));
  }
}

export function* toggleDND(action) {
  try {
    const res = yield call(
      PbxManager.toggleDND.bind(PbxManager, action.status)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(toggleDNDSuccess());
  } catch (err) {
    yield put(toggleDNDFailure(err));
  }
}

export function* execCall(action) {
  try {
    let { number } = action;
    if (action.addExitCode) {
      const exitCode = PbxManager.retrieveExitCode();
      if (exitCode) {
        number = exitCode + number;
      }
    }
    const res = yield call(PbxManager.callNumber.bind(PbxManager, number));
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(execCallSuccess());
  } catch (err) {
    yield put(execCallFailure(err));
  }
}

export function* hangupCall(action) {
  try {console.log(action.callid);
    const res = yield call(
      PbxManager.hangupCall.bind(PbxManager, action.callid)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(hangupCallSuccess());
  } catch (err) {
    yield put(hangupCallFailure(err));
  }
}

export function* hangupHoldCall(action) {
  try {
    const res = yield call(
      PbxManager.hangupHoldCall.bind(PbxManager, action.callid)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(hangupHoldCallSuccess());
  } catch (err) {
    yield put(hangupHoldCallFailure(err));
  }
}

export function* hangupChannel(action) {
  try {
    const res = yield call(
      PbxManager.hangupChannel.bind(PbxManager, action.channel)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(hangupChannelSuccess());
  } catch (err) {
    yield put(hangupChannelFailure(err));
  }
}

export function* blindTransfer(action) {
  try {
    let { number } = action.payload;
    if (action.payload.addExitCode) {
      const exitCode = PbxManager.retrieveExitCode();
      if (exitCode) {
        number = exitCode + number;
      }
    }
    const res = yield call(
      PbxManager.blindTransfer.bind(PbxManager, action.payload.callId, number)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(blindTransferSuccess(action.payload.callId));
  } catch (err) {
    yield put(blindTransferFailure(err));
  }
}

export function* queueTransfer(action) {
  try {
    const { number } = action.payload;
    const res = yield call(
      PbxManager.queueTransfer.bind(PbxManager, action.payload.callId, number)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(queueTransferSuccess(action.payload.callId));
  } catch (err) {
    yield put(queueTransferFailure(err));
  }
}

export function* holdCall(action) {
  try {
    const res = yield call(PbxManager.holdCall.bind(PbxManager, action.callid));
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(holdCallSuccess());
  } catch (err) {
    yield put(holdCallFailure(err));
  }
}

export function* unholdCall(action) {
  try {
    const res = yield call(
      PbxManager.unholdCall.bind(PbxManager, action.callid)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(unholdCallSuccess());
  } catch (err) {
    yield put(unholdCallFailure(err));
  }
}

export function* aidTransfer(action) {
  try {
    let { number } = action.payload;
    if (action.payload.addExitCode) {
      const exitCode = PbxManager.retrieveExitCode();
      if (exitCode) {
        number = exitCode + number;
      }
    }
    const res = yield call(
      PbxManager.attendedTransfer.bind(
        PbxManager,
        action.payload.callId,
        number
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(aidTransferSuccess());
  } catch (err) {
    yield put(aidTransferFailure(err));
  }
}

export function* parkCall(action) {
  try {
    const res = yield call(
      PbxManager.parkCall.bind(
        PbxManager,
        action.payload.number,
        action.payload.callId
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(parkCallSuccess());
  } catch (err) {
    yield put(parkCallFailure(err));
  }
}

export function* linkCalls() {
  try {
    const res = yield call(PbxManager.linkCalls.bind(PbxManager));
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(linkCallsSuccess());
  } catch (err) {
    yield put(linkCallsFailure(err));
  }
}

export function* switchCalls() {
  try {
    const res = yield call(PbxManager.switchCalls.bind(PbxManager));
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(switchCallsSuccess());
  } catch (err) {
    yield put(switchCallsFailure(err));
  }
}

export function* pickupCall(action) {
  try {
    const res = yield call(
      PbxManager.pickupCall.bind(
        PbxManager,
        action.payload.callid,
        action.payload.number
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(pickupCallSuccess());
  } catch (err) {
    yield put(pickupCallFailure(err));
  }
}

export function* pickupPark(action) {
  try {
    const res = yield call(
      PbxManager.parkAnswer.bind(PbxManager, action.parkId)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(pickupParkSuccess());
  } catch (err) {
    yield put(pickupParkFailure(err));
  }
}

export function* pickupQueue(action) {
  try {
    const res = yield call(
      PbxManager.queueAnswer.bind(
        PbxManager,
        action.payload.queueId,
        action.payload.queueName
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(pickupQueueSuccess());
  } catch (err) {
    yield put(pickupQueueFailure(err));
  }
}

export function* startConference(action) {
  try {
    const res = yield call(
      PbxManager.startConference.bind(
        PbxManager,
        action.payload.number,
        action.payload.callId
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(startConferenceSuccess());
  } catch (err) {
    yield put(startConferenceFailure(err));
  }
}

export function* endConference(action) {
  try {
    const res = yield call(
      PbxManager.endConference.bind(PbxManager, action.conferenceId)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(endConferenceSuccess());
  } catch (err) {
    yield put(endConferenceFailure(err));
  }
}

export function* fetchConference() {
  try {
    const webrtcActive = yield select(isWebrtcActive);
    const number =
      webrtcActive === 'ON'
        ? yield select(getMeWebrtcExtensionNumber)
        : yield select(getMeMainExtensionNumber);
    const res = yield call(
      PbxManager.retrieveConferenceData.bind(PbxManager, number)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(fetchConferenceSuccess(res.data));
  } catch (err) {
    yield put(fetchConferenceFailure(err));
  }
}

export function* joinConference() {
  try {
    const res = yield call(PbxManager.joinConference.bind(PbxManager));
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(joinConferenceSuccess());
  } catch (err) {
    yield put(joinConferenceFailure(err));
  }
}

export function* hangupUserConference(action) {
  try {
    const res = yield call(
      PbxManager.hangupUserInConference.bind(
        PbxManager,
        action.payload.number,
        action.payload.conferenceId
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(hangupUserConferenceSuccess());
  } catch (err) {
    yield put(hangupUserConferenceFailure(err));
  }
}

export function* fetchSearchedContacts(action) {
  let searched;
  try {
    if (action.params.filter) {
      const users = yield select(getUsersWithPhone);
      const meId = yield select(getMeId);
      let searchedUsers;
      if (
        !(
          action.params.numberTypes &&
          action.params.numberTypes.length === 1 &&
          action.params.numberTypes.indexOf('FAX') > -1
        )
      ) {
        searchedUsers = users
          .filter(
            (user) =>
              user.mainExtensionNumber &&
              user.mainExtensionNumber !== PbxSettings.DISABLED_PHONE_NUMBER &&
              user.id !== meId &&
              (user.fullname
                .toLowerCase()
                .indexOf(action.params.filter.toLowerCase()) > -1 ||
                user.mainExtensionNumber.indexOf(action.params.filter) > -1)
          )
          .map((user) => ({
            id: user.id,
            fullname: user.fullname,
            departmentFullname: user.departmentFullname,
            avatar: user.avatar,
            number: { type: 'EXTENSION', number: user.mainExtensionNumber },
            username: user.mainExtensionUsername,
            type: 'U',
          }));
      }
      const exitCode = PbxManager.retrieveExitCode();
      if (exitCode && action.params.filter.indexOf(exitCode) === 0) {
        action.params.filter = action.params.filter.substr(exitCode.length);
      }
      const contacts = yield call(api.phone.getSearchedContacts, action.params);
      if (contacts.status < 200 || contacts.status >= 400) {
        throw contacts.status;
      }
      const searchedContacts =
        contacts.status === 204
          ? []
          : contacts.data.data.map((contact) => ({
              id: contact.id,
              fullname: contact.fullname,
              avatar: contact.avatar,
              number: contact.number,
              type: contact.type,
              username: null,
            }));
      searched = sortBy(unionWith(searchedUsers, searchedContacts), [
        function (o) {
          return o.fullname.toLowerCase();
        },
      ]);
    } else {
      searched = [];
    }
    yield put(
      fetchSearchedContactsSuccess({
        data: searched,
        target: action.params.target,
      })
    );
  } catch (err) {
    yield put(fetchSearchedContactsFailure(err));
  }
}

export function* activateWebrtcPhone() {
  const eventEmitter = new EventEmitter();
  const emitterChannel = yield call(initWebrtcListener, eventEmitter);
  try {
    const response = yield call(
      PbxManager.activateWebrtcPhone.bind(PbxManager, eventEmitter)
    );
    if (response.status === PhoneEnums.CallsActionsResults.ko) {
      throw response.status;
    }
    nethesisJanusAttempt = 0;
    let webrtcActive;
    do {
      const payload = yield take(emitterChannel);
      if (payload.disconnect) {
        // QUI (JANUS DISCONNESSO)
        PbxManager.closeWebrtc.bind(PbxManager);
        yield put(webphoneDisconnected());
        return;
      }
      yield put(payload.action(payload.data));
      webrtcActive = yield select(isWebrtcActive);
    } while (webrtcActive === 'ON');
  } catch (err) {
    yield put(activateWebrtcPhoneFailure());
  }
}

export function* reconnectWebrtcPhone() {
  nethesisJanusAttempt += 1;
  const waiting = calculateReconnectWaiting(nethesisJanusAttempt);
  yield Utils.delay(waiting);
  yield call(activateWebrtcPhone);
}

export function* deactivateWebrtcPhone() {
  const otherSessionsNumber = yield select(otherSessions);
  try {
    const response = yield call(
      PbxManager.deactivateWebrtcPhone.bind(PbxManager)
    );
    if (!response.status) {
      yield put(
        deactivateWebrtcPhoneSuccess({ otherSessions: otherSessionsNumber > 0 })
      );
    } else if (response.status === PhoneEnums.CallsActionsResults.ko) {
      throw response.status;
    }
  } catch (err) {
    yield put(
      deactivateWebrtcPhoneFailure({ otherSessions: otherSessionsNumber > 0 })
    );
  }
}

export function* reactivateWebphone() {
  yield call(deactivateWebrtcPhone);
  yield Utils.delay(3000);
  yield call(activateWebrtcPhone);
}

export function* toggleMute(action) {
  try {
    const res = yield call(
      action.payload.muted
        ? PbxManager.muteCall.bind(PbxManager, action.payload.callId)
        : PbxManager.unmuteCall.bind(PbxManager, action.payload.callId)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(toggleMuteSuccess(action.payload.muted));
  } catch (err) {
    yield put(toggleMuteFailure(err));
  }
}

export function* answerCall() {
  try {
    const webrtcCallData = yield select(getIncomingCallJsep);
    if (!webrtcCallData) {
      yield put(setNoValidJsepCall());
    } else {
      const res = yield call(
        PbxManager.answerCall.bind(PbxManager, webrtcCallData)
      );
      if (res === PhoneEnums.CallsActionsResults.ko) {
        throw res;
      }
      yield put(answerCallSuccess());
    }
  } catch (err) {
    yield put(answerCallFailure(err));
  }
}

export function* declineCall(action) {
  try {
    const usingWebphone = yield select(isWebrtcInUse);
    const webrtcCallData = yield select(getIncomingCallJsep);
    if (usingWebphone && !webrtcCallData) {
      yield put(setNoValidJsepCall());
    } else {
      const incomingCalls = yield select(getMeIncomingCalls);
      const res = yield call(
        PbxManager.declineCall.bind(PbxManager, action.callid, incomingCalls)
      );
      if (res === PhoneEnums.CallsActionsResults.ko) {
        throw res;
      }
      yield put(declineCallSuccess());
    }
  } catch (err) {
    yield put(declineCallFailure(err));
  }
}

export function* recordCall(action) {
  try {
    const res = yield call(
      PbxManager.recordCall.bind(
        PbxManager,
        action.payload.callId,
        action.payload.users
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(recordCallSuccess());
  } catch (err) {
    yield put(recordCallFailure(err));
  }
}

export function* stopRecordCall(action) {
  try {
    const res = yield call(
      PbxManager.stopRecordCall.bind(
        PbxManager,
        action.payload.callId,
        action.payload.users
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(stopRecordCallSuccess());
  } catch (err) {}
}

export function* sendDtmf(action) {
  try {
    const res = yield call(PbxManager.sendDtmf.bind(PbxManager, action.key));
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(sendDtmfSuccess());
  } catch (err) {
    yield put(sendDtmfFailure(err));
  }
}

export function* spyCall(action) {
  try {
    const res = yield call(
      PbxManager.spyCall.bind(
        PbxManager,
        action.payload.callid,
        action.payload.number
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(spyCallSuccess());
  } catch (err) {
    yield put(spyCallFailure(err));
  }
}

export function* intrudeCall(action) {
  try {
    const res = yield call(
      PbxManager.intrudeCall.bind(
        PbxManager,
        action.payload.callid,
        action.payload.number
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(intrudeCallSuccess());
  } catch (err) {
    yield put(intrudeCallFailure(err));
  }
}

export function* togglePlayCalledBusy() {
  yield Utils.delay(3000);
  yield put(unsetCalledBusy());
}

export function* sendStopUseWebrtcPhone() {
  const userId = yield select(getMeId);
  const ackUid = generateAckUid(userId);
  const message = {
    channel: WsEnums.Channels.PRESENCE,
    topic: PresenceEnums.PresenceTopics.WEBPHONE,
    ackUid,
  };
  AckHandler.addPendingAck({
    ackUid,
    message,
    retry: sendStopUseWebrtcPhone,
  });
  WsManager.sendMessage(message);
  yield Utils.delay(2000);
  const error = yield select(sentStopUseWebphoneError);
  if (!error) {
    yield put(activateWebrtcPhoneRequest());
    yield put(fetchDefaultDeviceSuccess('webrtc'));
  }
}

export function* stopUseWebrtcPhone(action) {
  const userId = yield select(getMeId);
  const ackUid = generateAckUid(userId);
  const message = {
    channel: WsEnums.Channels.PRESENCE,
    topic: PresenceEnums.PresenceTopics.WEBPHONE,
    action: 'ACK',
    recipient: { type: 'SESSION', id: action.payload.senderUid },
    ackUid,
  };
  AckHandler.addPendingAck({
    ackUid,
    message,
    retry: stopUseWebrtcPhone,
  });
  // check if i am in a call
  const activeCalls = yield select(getMeCallsAndConferences);
  // if true  send ack success: false
  if (activeCalls.length > 0) {
    WsManager.sendMessage({
      ...message,
      payload: { allow: false, reason: 'PENDING_CALL' },
    });
  } else {
    // if false deregister janus ans send ack success: true
    // const payload = { otherSessions: true };
    yield put(deactivateWebrtcPhoneRequest());
    yield put(fetchDefaultDeviceSuccess('webrtc'));
    WsManager.sendMessage({
      ...message,
      payload: { allow: true },
    });
  }
}

export function* fetchPbxRoutes() {
  const supplier = yield select(getSupplier);
  if (supplier === PhoneEnums.PbxType.ABILIS) return;
  try {
    const res = yield call(api.phone.getRoutes);
    yield call(checkApiResponse, res);
    yield put(fetchPbxRoutesSuccess(res.data));
  } catch (err) {
    yield put(fetchPbxRoutesFailure(err));
  }
}

export function* savePbxRoute(action) {
  try {
    const res = yield call(
      action.params.id ? api.phone.updateRoute : api.phone.createRoute,
      action.params
    );
    yield call(checkApiResponse, res);
    yield put(savePbxRouteSuccess());
    yield put(fetchPbxRoutesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(savePbxRouteFailure(error));
  }
}

export function* fetchPbxQueues() {
  const supplier = yield select(getSupplier);
  if (supplier === PhoneEnums.PbxType.ABILIS) return;
  try {
    const res = yield call(api.phone.getQueues);
    yield call(checkApiResponse, res);
    yield put(fetchPbxQueuesSuccess(res.data));
  } catch (err) {
    yield put(fetchPbxQueuesFailure(err));
  }
}

export function* savePbxQueue(action) {
  try {
    const res = yield call(api.phone.createQueue, action.params);
    yield call(checkApiResponse, res);
    yield put(savePbxQueueSuccess());
    yield put(fetchPbxQueuesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(savePbxQueueFailure(error));
  }
}

export function* onTrunkUpdate(action) {
  const conferenceCallId = yield select(getMeConferenceId);
  if (conferenceCallId) {
    yield put(fetchConferenceRequest(action.payload));
  }
}

export function* addConferenceMemberWebrtc(action) {
  try {
    const webrtcNumber = yield select(getMeWebrtcExtensionNumber);
    const res = yield call(
      PbxManager.hangupUserInConference.bind(
        PbxManager,
        webrtcNumber,
        action.payload.conferenceId
      )
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    let { number } = action.payload;
    if (action.addExitCode) {
      const exitCode = PbxManager.retrieveExitCode();
      if (exitCode) {
        number = exitCode + number;
      }
    }
    yield Utils.delay(2000);
    const result = yield call(PbxManager.callNumber.bind(PbxManager, number));
    if (result === PhoneEnums.CallsActionsResults.ko) {
      throw result;
    }
    yield put(addConferenceMemberWebrtcSuccess());
  } catch (err) {
    yield put(addConferenceMemberWebrtcFailure(err));
  }
}

export function* traceWebrtcTrouble(action) {
  try {
    yield call(api.phone.traceWebrtcTrouble, {
      ...action.payload,
      log: Janus.retrieveLog().join('\n\n'),
    });
  } catch (err) {}
}

export default function* rootSaga() {
  yield takeLatest(INIT_MAIN_SUCCESS, fetchPhoneSettings);
  yield takeLatest(FETCH_PHONE_SETTINGS_REQUEST, fetchPhoneSettings);
  yield takeLatest(LOGIN_PHONE_REQUEST, loginPhone);
  yield takeLatest(LOGIN_PHONE_SUCCESS, getInitialPhonesStatus);
  yield takeLatest(LOGIN_PHONE_SUCCESS, fetchMePhoneRules);
  yield takeLatest(RELOGIN_PHONE_REQUEST, reloginPhone);
  yield takeLatest(LOGIN_PHONE_FAILURE, reloginPhone);
  yield takeLatest(PHONE_STATUS_CHANGES_FAILURE, loginPhone);
  yield takeLatest(
    INITIAL_ABILIS_PHONE_STATUS_SUCCESS,
    getAbilisPhonesStatusChanges
  );
  yield takeEvery(
    INITIAL_NETHESIS_PHONE_STATUS_SUCCESS,
    getNethesisPhonesStatusChanges
  );
  yield takeLatest(INITIAL_NETHESIS_PHONE_STATUS_SUCCESS, fetchConference);
  yield takeLatest(INITIAL_NETHESIS_PHONE_STATUS_SUCCESS, fetchDefaultDevice);
  yield takeEvery(
    ABILIS_PHONE_STATUS_CHANGES_SUCCESS,
    getAbilisPhonesStatusChanges
  );
  yield takeEvery(FETCH_CONFERENCE_REQUEST, fetchConference);
  yield takeEvery(FETCH_INTERLOCUTOR_DATA_REQUEST, fetchInterlocutorData);
  yield takeLatest(EXEC_CALL_REQUEST, execCall);
  yield takeLatest(HANGUP_CALL_REQUEST, hangupCall);
  yield takeLatest(HANGUP_HOLD_CALL_REQUEST, hangupHoldCall);
  yield takeLatest(HANGUP_CHANNEL_REQUEST, hangupChannel);
  yield takeLatest(TOGGLE_PHONE_DND_REQUEST, toggleDND);
  yield takeLatest(ENABLE_PHONE_DND, toggleDND);
  yield takeLatest(DISABLE_PHONE_DND, toggleDND);
  yield takeLatest(BLIND_TRANSFER_REQUEST, blindTransfer);
  yield takeLatest(QUEUE_TRANSFER_REQUEST, queueTransfer);
  yield takeLatest(FETCH_SEARCHED_CONTACTS_REQUEST, fetchSearchedContacts);
  yield takeLatest(HOLD_CALL_REQUEST, holdCall);
  yield takeLatest(UNHOLD_CALL_REQUEST, unholdCall);
  yield takeLatest(AID_TRANSFER_REQUEST, aidTransfer);
  yield takeLatest(LINK_CALLS_REQUEST, linkCalls);
  yield takeLatest(PARK_CALL_REQUEST, parkCall);
  yield takeLatest(SWITCH_CALLS_REQUEST, switchCalls);
  yield takeLatest(PICKUP_CALL_REQUEST, pickupCall);
  yield takeLatest(PICKUP_PARK_REQUEST, pickupPark);
  yield takeLatest(PICKUP_QUEUE_REQUEST, pickupQueue);
  yield takeLatest(TOGGLE_MUTE_REQUEST, toggleMute);
  yield takeLatest(ACTIVATE_WEBRTC_REQUEST, activateWebrtcPhone);
  yield takeLatest(ACTIVATE_WEBRTC_FAILURE, reconnectWebrtcPhone);
  yield takeLatest(WEBPHONE_DISCONNECTED, reconnectWebrtcPhone);
  yield takeLatest(DEACTIVATE_WEBRTC_REQUEST, deactivateWebrtcPhone);
  yield takeLatest(REACTIVATE_WEBRTC_PHONE, reactivateWebphone);
  yield takeLatest(ANSWER_CALL_REQUEST, answerCall);
  yield takeLatest(DECLINE_CALL_REQUEST, declineCall);
  yield takeLatest(RECORD_CALL_REQUEST, recordCall);
  yield takeLatest(STOP_RECORD_CALL_REQUEST, stopRecordCall);
  yield takeLatest(SEND_DTMF_REQUEST, sendDtmf);
  yield takeLatest(START_CONFERENCE_REQUEST, startConference);
  yield takeLatest(START_CONFERENCE_SUCCESS, fetchConference);
  yield takeLatest(END_CONFERENCE_REQUEST, endConference);
  yield takeLatest(END_CONFERENCE_SUCCESS, fetchConference);
  yield takeEvery(FETCH_MEMBER_DATA_REQUEST, fetchMemberData);
  yield takeLatest(JOIN_CONFERENCE_REQUEST, joinConference);
  yield takeLatest(JOIN_CONFERENCE_SUCCESS, fetchConference);
  yield takeLatest(HANGUP_USER_CONFERENCE_REQUEST, hangupUserConference);
  yield takeLatest(HANGUP_USER_CONFERENCE_SUCCESS, fetchConference);
  yield takeLatest(SPY_CALL_REQUEST, spyCall);
  yield takeLatest(INTRUDE_CALL_REQUEST, intrudeCall);
  yield takeLatest(LOGOUT_SUCCESS, logoutPhone);
  yield takeLatest(BUSY_WEBRTC_CALL, togglePlayCalledBusy);
  yield takeLatest(SEND_STOP_USE_WEBPHONE_REQUEST, sendStopUseWebrtcPhone);
  yield takeLatest(STOP_USE_WEBPHONE, stopUseWebrtcPhone);
  yield takeLatest(FETCH_PBX_ROUTES_REQUEST, fetchPbxRoutes);
  yield takeLatest(SAVE_PBX_ROUTE_REQUEST, savePbxRoute);
  yield takeLatest(FETCH_PBX_QUEUES_REQUEST, fetchPbxQueues);
  yield takeLatest(SAVE_PBX_QUEUE_REQUEST, savePbxQueue);
  yield takeLatest(RECEIVED_TRUNK_UPDATE, onTrunkUpdate);
  yield takeLatest(
    ADD_CONFERENCE_MEMBER_WEBRTC_REQUEST,
    addConferenceMemberWebrtc
  );
  yield takeLatest(ADD_CONFERENCE_MEMBER_WEBRTC_SUCCESS, fetchConference);
  yield takeLatest(TRACE_WEBRTC_TROUBLE, traceWebrtcTrouble);
}
